import React, { useEffect, useRef, useState } from "react"
import HostRadio from "./hostRadio"
import "../assets/styles/components/host.scss"
import { useInputChange } from "../custom-hook/useForm"
import { Link, navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { handleHostType } from "../helper/firebase/transaction/profile.trx"
import { Dispatch } from "@reduxjs/toolkit"

const Registered = () => {
  const [formInput, setFormInput] = useState({ form: "regType" })

  const dispatch = useDispatch()
  const userStatus = useSelector((state: any) => state.auth.userStatus)
  const kycProfileStatus = useSelector((state: any) => state.profileKyc.kycProfileStatus)

  const customHandleHostType = handleHostType(dispatch as Dispatch)

  const [isPrivate, setIsPrivate] = useState(false)
  const [isProfessional, setIsProfessional] = useState(false)
  const [input, handleInputChange] = useInputChange()
  const [to, setTo] = useState("")
  const regTypeRef = useRef("")
  const hostTypeRef = useRef("")

  const handlePrivate = () => {
    setIsPrivate(true)
    setIsProfessional(false)
  }

  const handleProfessional = () => {
    setIsPrivate(false)
    setIsProfessional(true)
  }

  useEffect(() => {
    regTypeRef.current = input.regType
    hostTypeRef.current = input.hostType
    setFormInput({ ...formInput, ...input })
    handleRoute()
  }, [input.regType, input])

  const handleRoute = () => {
    if (kycProfileStatus?.profile?.hostType === "individual") {
      switch (input.regType) {
        case "private":
          setTo("/account-details/individual-host/your-identity")
          break

        case "professional":
          setTo("/account-details/single-professional-host/your-identity")
          break

        default:
          break
      }
    } else if (kycProfileStatus?.profile?.hostType === "partnership") {
      switch (input.regType) {
        case "private":
          setTo("/account-details/private-partnership-host/your-identity")
          break

        case "professional":
          setTo("/account-details/professional-partnership-host/your-identity")
          break

        default:
          break
      }
    }
  }


  return (
    <div className="host">
      <h3 className="h3-heading">Are you registered as a business?</h3>
      <div className="desc-1">
        This helps us ask the right questions in the next few steps.
        <span>
          <button className="learn-btn">Learn more</button>
        </span>
      </div>
      <div className="host-types">
        <HostRadio
          active={isPrivate}
          name="regType"
          value="private"
          onClick={(event: any) => {
            handlePrivate()
            handleInputChange(event)
          }}
        >
          <div className="host-type">
            <div className="host-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={{ display: "block", height: "40px", width: "40px" }}
              >
                <path d="M31.707,15.293,29,12.58594,18.12109,1.707a3.07251,3.07251,0,0,0-4.24218,0L3,12.58594.293,15.293,1.707,16.707,3,15.41406V28a2.00229,2.00229,0,0,0,2,2H27a2.0026,2.0026,0,0,0,2-2V15.41406l1.293,1.293ZM9.0799,28A7.00456,7.00456,0,0,1,15,22.07983V19.78815a2.49986,2.49986,0,1,1,2,.00012V22.0799A7.00512,7.00512,0,0,1,22.9201,28ZM27,28H24.92218A9.00972,9.00972,0,0,0,19.252,20.60742a4.5,4.5,0,1,0-6.5039,0A9.01069,9.01069,0,0,0,7.07782,28H5V13.41406l10.293-10.293a1.00142,1.00142,0,0,1,1.41406,0L27,13.41406Z"></path>
              </svg>
            </div>
            <div className="host-title">Private</div>
            <div className="host-desc">
              You’re typically a private or non-professional host if hosting on Brooi isn’t your primary profession or
              source of income.
            </div>
          </div>
        </HostRadio>
        <HostRadio
          active={isProfessional}
          name="regType"
          value="professional"
          onClick={(event: any) => {
            handleProfessional()
            handleInputChange(event)
          }}
        >
          <div className="host-type">
            <div className="host-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={{ display: "block", height: "40px", width: "40px" }}
              >
                <path d="M16,1A15,15,0,1,0,31,16,15.017,15.017,0,0,0,16,1ZM3.05066,17H8.02612a26.77654,26.77654,0,0,0,.62036,5H4.479A12.89322,12.89322,0,0,1,3.05066,17ZM17,8V3.21014c1.3941.559,2.74292,2.25617,3.68939,4.78986Zm4.30353,2a24.39725,24.39725,0,0,1,.67157,5H17V10ZM15,3.21014V8H11.31061C12.25708,5.46631,13.6059,3.76917,15,3.21014ZM15,10v5H10.0249a24.39725,24.39725,0,0,1,.67157-5ZM8.02612,15H3.05066a12.89382,12.89382,0,0,1,1.42822-5h4.1676A26.77654,26.77654,0,0,0,8.02612,15Zm1.99878,2H15v5H10.69647A24.39725,24.39725,0,0,1,10.0249,17ZM15,24v4.78986c-1.3941-.559-2.74292-2.25617-3.68939-4.78986Zm2,4.78986V24h3.68939C19.74292,26.53369,18.3941,28.23083,17,28.78986ZM17,22V17h4.9751a24.39725,24.39725,0,0,1-.67157,5Zm6.97394-5h4.9754a12.89225,12.89225,0,0,1-1.4284,5h-4.1673A26.77666,26.77666,0,0,0,23.97394,17Zm0-2a26.77666,26.77666,0,0,0-.6203-5H27.521a12.89292,12.89292,0,0,1,1.42834,5Zm2.25165-7h-3.432a15.02436,15.02436,0,0,0-2.00617-4.075A13.04751,13.04751,0,0,1,26.22559,8ZM11.213,3.9248A15.02719,15.02719,0,0,0,9.20654,8H5.77429A13.04727,13.04727,0,0,1,11.213,3.9248ZM5.77441,24H9.20654a15.02825,15.02825,0,0,0,2.00635,4.07513A13.0473,13.0473,0,0,1,5.77441,24Zm15.01307,4.075A15.02591,15.02591,0,0,0,22.79358,24h3.43188A13.04861,13.04861,0,0,1,20.78748,28.075Z"></path>
              </svg>
            </div>
            <div className="host-title">Professional Host</div>
            <div className="host-desc">
              You’re typically a professional host if hosting on Brooi is your primary profession or source of income,
              or if you’re part of an established business, like a boutique hotel or property management company.
            </div>
          </div>
        </HostRadio>
      </div>
      <div className="lower-wrapper">
        <div className="button-wrapper">
          {!regTypeRef.current ? (
            <button type="submit" className="next-btn">
              Next
            </button>
          ) : (
            <Link to={to}>
              <button
                type="submit"
                onClick={() => customHandleHostType(kycProfileStatus, formInput, userStatus)}
                className="next-btn"
              >
                Next
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default Registered
