import React from "react"
import HostingLayout from "../../components/hostingLayout"
import Registered from "../../components/registered"

const RegisteredPage = () => {
  return (
    <div className="registered-page">
      <HostingLayout>
        <Registered />
      </HostingLayout>
    </div>
  )
}

export default RegisteredPage
